import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { GoogleLogin } from '@react-oauth/google';


function App() {

    const[user_threadID, setUser_threadID] = React.useState("");
    let textFieldValue = "Dex: Hello! My name is Dex, and I am here to help you remember important interactions so that you can have stronger and more consistent connections with friends, family, or your professional network. Through our conversation, I will help you to record your interactions with people and summarize the event with some helpful takeways. To start a conversation, provide details on with whom, when and where the conversation took place, and the topics you discussed.\n\nDex: For example, start your conversation like this: \"Yesterday, I had lunch with my friend James at Taco Bell, and we talked about his recent adventure to Peru\"\n\nDex: You can be brief. I'll ask follow-up questions and summarize our conversation at the end.\n\n";

    // start session and get a new thread_id
    const startSession = () => {
        fetch('https://rolodex-backend-xqcbsl4rra-uc.a.run.app/api/start-session', {   // Prod
        //fetch('http://localhost:5000/api/start-session', {  // Local  
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            // Include any data you need to send to the Flask API (no data for now)
          })
        })
        .then(response => response.json())
        .then(data => {
          // Setting state with use state
          setUser_threadID(data.thread_id);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    // check if there is a thread_id and request it if not
    React.useEffect(() => {
      if (user_threadID === "") {
        startSession();
      }
    }, [user_threadID]);

    // Handle Google login response
    const responseGoogle = (response) => {
      // console.log(response);
      // Extract the ID token from the Google auth response
    const token = response.credential;

    fetch('https://rolodex-backend-xqcbsl4rra-uc.a.run.app/login/google', {   // Prod
    //fetch('http://localhost:5000/login/google', {   // Local
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({token: token})
      })
      .then(response => response.json())
      // .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
      };

    // Send user input to backend and display ChatGPT response in chatbox
    const sendMessage = () => {
        const inputField = document.getElementById('user_input');
        // console.log("User input: " + inputField.value);
        const chatBox = document.getElementById('chat');
        const userInput = inputField.value;
        // Display user input in chatbox
        chatBox.value += "You: " + userInput + "\n\n";

        // Send user input to backend
        fetch('https://rolodex-backend-xqcbsl4rra-uc.a.run.app/api/ask', {   // Prod
        //fetch('http://localhost:5000/api/ask', {   // Local
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"message": userInput, "thread_id": user_threadID})
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data); // Handle the response from the Flask API
            chatBox.value += "Dex: " + data.response + "\n\n";
          })
          .catch(error => {
            console.error('Error:', error);
          });

        // Display ChatGPT response in chatbox
        // chatBox.value += "Dex: " + data.response + "\n\n";
        
        // Clear input field
        inputField.value = '';
    }

    const saveFile = () => {
            
      const chatBox = document.getElementById('chat');
      const fileName = "my-text-file.txt";
      const file = new File([chatBox.value], fileName, {
          type: "text/plain",
      });

      const url = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();

      URL.revokeObjectURL(url);
    }

    // Function to handle Enter key press
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        // Call the submit function when Enter key is pressed
        sendMessage();
      }
    };

  return (
    <div>
        <Container maxWidth="sm">
            <Box spacing={2} marginTop={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        inputProps={{ readOnly: true }}
                        id="chat"
                        label="Chat with Dex"
                        multiline
                        rows={15}
                        defaultValue={textFieldValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label="Type message here" id="user_input" onKeyPress={handleKeyPress}/>
                    </Grid>
                </Grid>
                <Grid container spacing={1} marginTop={1}>
                    <Grid item xs={3}>
                        <Button variant="contained" endIcon={<SendIcon />} onClick={sendMessage}>Send</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" endIcon={<SaveAltIcon />} onClick={saveFile}>Save</Button>
                    </Grid>
                    <Grid item>
                      <GoogleLogin clientId="YOUR_CLIENT_ID" buttonText="Login with Google" onSuccess={responseGoogle} onFailure={responseGoogle}/>
                    </Grid>
                </Grid>
            </Box>
        </Container>
      {/* Your components and JSX here */}
      
      
    </div>
  );
}

export default App;