import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './app';

ReactDOM.render(
  <GoogleOAuthProvider clientId="743882844361-jqa79e8lvuf7rjcg3h5h463h3093tcr8.apps.googleusercontent.com">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);